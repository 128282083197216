import $ from 'jquery';

class Video {
	constructor() {
		$('.video-popup2').magnificPopup({
			type: 'iframe',

			iframe: {
				patterns: {
					youtube: {
						index: 'youtube.com',
						id: 'v=',
						src: `//www.youtube.com/embed/%id%?modestbranding=1&rel=0`
					},
				},
			},
		});
	}
}

$(() => new Video());
